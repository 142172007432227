.table .thead-bg th {
    background-color: #1a1840 !important;
    color: white;
}

@import '~bootstrap/scss/bootstrap';

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}
body {
    background-color: #eaeaea;
}

.greenpay-color-1 {
    background-color: #03ddd0;
}

.greenpay-color-2 {
    background-color: #25abef;
}

.payment-invoice-1 {
    color: #1a1840;
}

.payment-invoice-2 {
    color: #2aa6f0 !important;
}

.center-full-screen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-grey {
    background-color: #eaeaea;
}

.bg-blue {
    background-color: #1a1840;
}

.text-white {
    color: white;
}

.border-radius-1 {
    border-radius: 10px !important;
    overflow: hidden;
}

.spanish {
    background-image: url('./assets/svg/spanish.svg');
    background-repeat: no-repeat;
    width: 40px;
}

.english {
    background-image: url('./assets/svg/english.svg');
    background-repeat: no-repeat;
    width: 40px;
}

.credix {
    filter: brightness(0) saturate(100%) invert(58%) sepia(1%) saturate(1415%)
        hue-rotate(336deg) brightness(92%) contrast(82%);
    height: 28px;
    width: 45px;
}

.default-credit-card {
    filter: brightness(0) saturate(100%) invert(58%) sepia(1%) saturate(1415%)
        hue-rotate(336deg) brightness(92%) contrast(82%);
}

.visa-icon {
    padding-left: 30px;
    background-image: url('./assets/svg/visa.svg');
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.master-card-icon {
    padding-left: 30px;
    background-image: url('./assets/svg/mc.svg');
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.amex-card-icon {
    padding-left: 30px;
    background-image: url('./assets/svg/amex.svg');
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.credix-card-icon {
    padding-left: 30px;
    background-image: url('./assets/svg/credix.svg');
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.default-card-icon {
    padding-left: 30px;
    background-image: url('./assets/svg/creditcarddefault.svg');
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
:not(.btn-check) + .btn:active {
    border-color: transparent;
}

#mainLogo {
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    margin: 0 auto;
    position: relative;
}

#frame {
    width: 500px;
    background: #fff;
    border-radius: 0;
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

#frame_header {
    height: 122px;
    margin-top: 15px;
}

.headerFiller {
    width: 25px;
    height: 25px;
    padding: 5px;
    margin: 10px;
}
#step_up_iframe {
    position: absolute;
    top: 25%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #eaeaea;
}
.div-iframe {
    display: flex;
    justify-content: center;
    align-items: center;
}
.div-iframe::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    z-index: 999;
}

/* Contenedor principal del plan */
.plan-container {
    border: 2px solid #133e8d;
    border-radius: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.plan-label-and-image {
    display: flex;
    align-items: center; 
    gap: 2px;
    margin-bottom: 10px; 
    margin-top: 10px;
}

/* Contenedor de las imágenes */
.image-container {
    display: flex;
    align-items: center;
}

/* Imagen de Tasa Cero */
.logo-plan-cero {
    width: 180px; 
    height: auto; 
    object-fit: contain; 
    margin: 0;
}

/* Etiquetas */
.plan-labels {
    flex-direction: column;
    line-height: 1.5;
}

.plan-label-blue {
    color:  #133e8d;
    font-weight: bold;
    font-size: 0.7rem;
}

.plan-label-gray {
    color: gray;
    font-size: 0.7rem; 
}

/* Formulario */
.plan-form {
    margin: 0;
}
